module.exports = {
  siteTitle: 'zipunk公式ブログ「zipunk blog」',
  siteTitleAlt: 'zipunk公式ブログ「zipunk blog」', // This allows an alternative site title for SEO schema.
  publisher: 'zipunk Co., Ltd.', // Organization name used for SEO schema
  siteDescription:
    '株式会社zipunkの運営する「zipunk blog」では、Webサイトアクセスデータ活用やデジタルマーケティングに役立つ情報を発信していきます。',
  siteUrl: 'https://blog.zipunk.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 13, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 12, // Number of posts shown on paginated pages
  author: 'zipunk Co., Ltd.', // Author for RSS author segment and SEO schema
  authorUrl: 'https://blog.zipunk.com/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '', // Change for Twitter Cards
  shortTitle: 'zipunk blog', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/zipunk_eyecatch_1200.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/zipunk_logo_512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#c68b21', // Used for Offline Manifest
  copyright: 'Copyright © 2020 zipunk Co., Ltd.', // Copyright string for the RSS feed
}
