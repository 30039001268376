import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Header = styled.header`
  background: white;
  width: 100%;
  padding: 1.5em 0;
`

const ImgLogo = styled.img`
  height: 2.5rem;
  width: auto;
`

const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
    &.is-hidden-tablet {
      @media (max-width: ${props => props.theme.responsive.medium}) {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
    color: DarkGray;
    font-weight: 600;
    transition: all 0.2s;
    &:hover {
      color: black;
    }
  }
`

const SearchFormWrapperForTabletAndDesktop = styled.div`
  margin: 2em auto;
  display: none;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    display: block;
  }
`

const NativeLink = styled.a`
  &:hover {
    color: 'black';
    cursor: pointer;
  }
`

const activeLinkStyle = {
  color: 'black',
}

const SearchForm = styled.form`
  min-width: 300px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  input {
    font-family: inherit;
    font-size: inherit;
    border: none;
    outline: none;
    background: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.base};
    border-radius: 2px;
    padding: 1em;
    &::-webkit-input-placeholder {
      color: gray;
    }
    &::-moz-placeholder {
      color: gray;
    }
    &:-ms-input-placeholder {
      color: gray;
    }
    &:-moz-placeholder {
      color: gray;
    }
    &:required {
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }
  }
`

const Query = styled.input`
  margin: 0 1em;
  width: 100%;
  flex-grow: 1;
`

const Submit = styled.button`
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
`

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      q: '',
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  contactForm = () => {
    return (
      <SearchForm action={`/search`} method="get">
        <Submit type="submit">
          <img src="/icons/search.svg" style={{ width: '20px' }} />
        </Submit>
        <Query
          name="q"
          type="text"
          placeholder="キーワード検索"
          value={this.state.q}
          onChange={this.handleInputChange}
          required
        />
      </SearchForm>
    )
  }

  render() {
    return (
      <Header>
        <Nav>
          <ul>
            <li>
              <Link to="/" activeStyle={activeLinkStyle}>
                <ImgLogo src="/logos/zipunk_logo_menu_800.png" alt="zipunk" />
              </Link>
            </li>
            <li>
              <NativeLink href="https://zipunk.com" target="_blank">
                Company
              </NativeLink>
            </li>
            <li>
              <Link to="/contact" activeStyle={activeLinkStyle}>
                Contact
              </Link>
            </li>
            <li className="is-hidden-tablet">{this.contactForm()}</li>
          </ul>
          <SearchFormWrapperForTabletAndDesktop>
            {this.contactForm()}
          </SearchFormWrapperForTabletAndDesktop>
        </Nav>
      </Header>
    )
  }
}

export default Menu
